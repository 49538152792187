var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("stepNav", {
        attrs: {
          name: _vm.$t("roof.pick_rooftile"),
          validStep: _vm.isStepComplete([
            "rooftile",
            "rooftileColor",
            "rooftileTreatment",
          ]),
        },
      }),
      _c("radioButtonGroup", {
        attrs: { label: _vm.$t("step.roof_tile"), options: _vm.rooftiles },
        on: {
          change: function ($event) {
            ;[_vm.validateRooftileTreatment(), _vm.validateRooftileColor()]
          },
        },
        model: {
          value: _vm.rooftile,
          callback: function ($$v) {
            _vm.rooftile = $$v
          },
          expression: "rooftile",
        },
      }),
      _vm.rooftile
        ? _c("radioButtonGroup", {
            attrs: {
              label: _vm.$t("breadcrumb.tile_treatment"),
              options: _vm.rooftileTreatments,
            },
            on: { change: _vm.validateRooftileColor },
            model: {
              value: _vm.rooftileTreatment,
              callback: function ($$v) {
                _vm.rooftileTreatment = $$v
              },
              expression: "rooftileTreatment",
            },
          })
        : _vm._e(),
      _vm.rooftile && _vm.rooftileTreatment
        ? _c("radioButtonGroup", {
            attrs: {
              label: _vm.$t("breadcrumb.tile_color"),
              options: _vm.rooftileColors,
            },
            model: {
              value: _vm.rooftileColor,
              callback: function ($$v) {
                _vm.rooftileColor = $$v
              },
              expression: "rooftileColor",
            },
          })
        : _vm._e(),
      _vm.rooftile && _vm.rooftileArticle
        ? _c("optionItemImg", {
            attrs: {
              option: _vm.rooftileArticle,
              selected: _vm.rooftileColor !== null,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }